.wrapper {
    min-height: calc(100vh - 61px);
    max-height: calc(100vh - 61px);
    color: rgb(0, 0, 0);
    overflow: auto;
    font-size: 30px;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
}

.inputDiv {
    /* border: 1px solid rgb(88, 36, 36); */
    margin-top: 30px;
    width: fit-content;
    display: flex;
    justify-content: center;
}

.idInput {
    /* border: 1px solid red; */
    font-size: 25px;
    padding: 5px;
    width: 50%;
}

.idSubmit {
    /* border: 1px solid red; */
    font-size: 25px;
    padding: 5px;
    width: 110px;
}

.cardDiv {
    /* border: 1px solid red; */
    margin: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 150px;
}

.card {
    /* border: 1px solid red; */
    border-radius: 8px;
    padding: 15px;
    /* background-color: black; */
    background-color: #1c1e22;
    height: fit-content;
    color: white;
    margin: 15px;
}

.cardTitle {
    /* border: 1px solid red; */
    font-weight: bold;
}

.cardAmount {
    /* border: 1px solid red; */
    font-size: 20px;
    margin: 10px 0px;
}

.statuslabel {
    /* border: 1px solid red; */
    font-size: 20px;
    display: flex;
    margin: 10px 0px;
}

.statustext1 {
    /* border: 1px solid red; */
}

.statustext2 {
    /* border: 1px solid red; */
    font-weight: bold;
    font-size: 22px;
    margin: 0px 10px;
}

.buttonDiv {
    display: flex;
    margin: 10px 0px;
}

.completeBtn, .approveBtn {
    width: 150px;
}

.completeBtn {
    font-size: 20px;
    padding: 5px;
    margin-right: 5px;
    cursor: pointer;
}

.approveBtn {
    /* border: 1px solid red; */
    font-size: 20px;
    padding: 5px;
    margin-left: 5px;
    cursor: pointer;
}


@media screen and (max-width: 600px) {
    .wrapper {
        flex-direction: column;
        align-items: center;
    }
    
}